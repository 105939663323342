import React, { Component, useState, useEffect, useContext } from "react";
import { Button, Form } from "react-bootstrap";
import LoadingSpinner from "../../Assets/LoadingSpinner/LoadingSpinner";
import { PostData } from "../../Services/PostData";
import "./Profile.css";
import InputGroup from "react-bootstrap/InputGroup";
import { SigninContext } from "../../Context/Context";
//TODO #22 no permite editar el mail
//TODO #23 no pide reingresar el mail/pass desde dashboard
export default function Profile(props) {
  const { pwChange, setPWChange, setProfileFill, profileFill } =
    useContext(SigninContext);
  const [isLoading, setIsLoading] = useState(true);
  const [profile, setProfile] = useState({
    apellido: "",
    boca: "",
    dni: "",
    email: "",
    legajo: "",
    nombre: "",
    profileFill: 0,
    pswChange: 0,
    rol: "",
  });
  const funcion = {};
  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    console.log(e.target.email.value);
    funcion.Function = "postUserProfile";
    funcion.RequestType = "Post";
    funcion.usrToken = localStorage.getItem("usrToken");
    funcion.data = { email: profile.email };
    PostData("POST", funcion).then((result) => {
      if (props.isForced != undefined && props.isForced) {
        setProfileFill(false);
      }
      setIsLoading(false);
      setProfile(result);
    }, []).catch((error) => {
      //setNewsTitle("Error de conexión, por favor intente nuevamente.");
      setIsLoading(false);
    });
  };

  useEffect(() => {
    funcion.Function = "getProfile";
    funcion.RequestType = "Request";
    funcion.usrToken = localStorage.getItem("usrToken");
    funcion.data = {};
    PostData("POST", funcion).then((result) => {
      setIsLoading(false);
      setProfile(result);
    }, []).catch((error) => {
      //setNewsTitle("Error de conexión, por favor intente nuevamente.");
      setIsLoading(false);
    });
  }, []);

  if (isLoading) {
    return (
      <div>
        <h1 className="col-12">Mi Perfil</h1>
        <LoadingSpinner />
      </div>
    );
  } else {
    return (
      <div className="profileItem">
        <div className="profileTitle">
          <h1>Mi Perfil</h1>
        </div>
        <div className="break" />
        {props.isForced ? (
          <h2>
            {" "}
            Te pedimos que completes tu email para poder asegurarnos que puedas
            recuperar tu contraseña en caso de que lo necesites.{" "}
          </h2>
        ) : (
          <></>
        )}
        <div className="break" />

        <div className="profileForm">
          <Form className="profile" onSubmit={handleSubmit}>
            <InputGroup className="mb-3">
              <InputGroup.Text className="basic-addon1">Nombre</InputGroup.Text>
              <Form.Control
                type="text"
                placeholder="Nombre"
                name="nombre"
                value={profile.nombre}
                disabled
              />
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroup.Text className="basic-addon1">
                Apellido
              </InputGroup.Text>
              <Form.Control
                type="text"
                placeholder="Apellido"
                name="apellido"
                value={profile.apellido}
                disabled
              />{" "}
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroup.Text className="basic-addon1">Email</InputGroup.Text>
              <Form.Control
                type="text"
                placeholder="Email"
                name="email"
                value={profile.email}
                onChange={(e) =>
                  setProfile({ ...profile, email: e.target.value })
                }
              />
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroup.Text className="basic-addon1">Legajo</InputGroup.Text>
              <Form.Control
                type="text"
                placeholder="Legajo"
                name="legajo"
                value={profile.legajo}
                disabled
              />{" "}
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroup.Text className="basic-addon1">DNI</InputGroup.Text>
              <Form.Control
                type="text"
                placeholder="DNI"
                name="dni"
                value={profile.dni}
                disabled
              />{" "}
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroup.Text className="basic-addon1">Boca</InputGroup.Text>
              <Form.Control
                type="text"
                placeholder="Boca"
                name="boca"
                value={profile.boca}
                disabled
              />{" "}
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroup.Text className="basic-addon1">Rol</InputGroup.Text>
              <Form.Control
                type="text"
                placeholder="Rol"
                name="rol"
                value={profile.rol}
                disabled
              />{" "}
            </InputGroup>
            <InputGroup className="mb-3">
              <Button variant="warning" style={{ width: "100%" }} type="submit">
                Guardar
              </Button>
            </InputGroup>
          </Form>
        </div>
      </div>
    );
  }
}
