import React, { Component, useEffect, useState } from "react";
import "./CapacitacionesModule.css";
import { PostData } from "../../../Services/PostData";
import Moment from "moment";

//

export default function CapacitacionesModule(props) {
  const [capCalled, SetCapCalled] = useState(0);

  if (props.start) {
    return (
      <>
        <div className="">
          <h2>Estado de Capacitaciones</h2>
          Este es el estado general de tus capacitaciones. Tene en cuenta que
          "Desaprobado o no realizado" puede significar que nunca hiciste la
          capacitacion, se venció o la desaprobaste. Para mas informacion
          consulta con tu supervisor.
          <br />
          <br />
        </div>
        {Object.keys(props.cap).map((key) => {
          return (
            <div key={key}>
              <h4>{props.cap[key].nombre}</h4>
              <div className="flx">
                <div key={`status` + props.cap[key].ID}>Estado:</div>
                <div>
                  {props.cap[key].status != undefined
                    ? "Aprobado"
                    : "Desaprobado o no realizado"}
                </div>
              </div>
              <div className="flx">
                <div key={props.cap[key].ID}>Fecha de Realizacion:</div>
                <div>
                  {props.cap[key].fecha != undefined
                    ? Moment(props.cap[key].fecha).format("DD-MM-YYYY")
                    : "-"}
                </div>
              </div>
              <div className="flx">
                <div key={props.cap[key].ID}>Fecha de Vencimiento:</div>
                <div>
                  {props.cap[key].venc != undefined
                    ? Moment(props.cap[key].venc).format("DD-MM-YYYY")
                    : "-"}
                </div>
              </div>
            </div>
          );
        })}
      </>
    );
  } else {
    //window.API.reset();
    //window.API.LMSInitialize();

    window.API.on("LMSInitialize", function () {
      window.API.apiLogLevel = 5;
      window.API.cmi.core._student_id = localStorage.getItem("usrToken");
      window.API.cmi.core._entry = props.cap.ID;
      console.log("iniciado");
    });

    return (
      <>
        <iframe
          width={"100%"}
          height={"100%"}
          src={"/capacitaciones/" + props.cap.React + "/index_lms.html"}
        ></iframe>
      </>
    );
  }
}
