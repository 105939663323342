import React, { Component, useState, useEffect } from "react";
import LoadingSpinner from "../../../Assets/LoadingSpinner/LoadingSpinner";
import { PostData } from "../../../Services/PostData";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import "./Post.css";
export default function BlogPost() {
  const [isLoading, setIsLoading] = useState(true);
  const [news, setNews] = useState([]);
  const routeParams = useParams();
  const funcion = {};
  useEffect(() => {
    funcion.Function = "getNew";
    funcion.RequestType = "Request";
    funcion.Event = "NEWS";
    funcion.usrToken = localStorage.getItem("usrToken");
    let start = -1;
    if (routeParams.id != undefined) {
      start = parseInt(routeParams.id);
    }
    funcion.data = { id: start };
    PostData("POST", funcion).then((result) => {
      setNews(result[0]);
      setIsLoading(false);
    }, []);
  }, []);

  if (isLoading) {
    return (
      <div>
        <LoadingSpinner />
      </div>
    );
  } else {
    return (
      <div className="postHolder">
        <div className="">
          {news.postImg != undefined && news.postImg != "" ? (
            <>
              <img className="postImg" src={news.postImg} />
              <div className="break" />
              <h4>{news.postTitle}</h4>
              <div className="break" />
              <p>{news.postCopy}</p>
            </>
          ) : (
            <>
              <h1>{news.newTitle}</h1>
              <div className="break" />
              <div
                className="postText"
                dangerouslySetInnerHTML={{ __html: news.newBody }}
              />
            </>
          )}
        </div>
      </div>
    );
  }
}
