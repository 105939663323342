import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  Fragment,
} from "react";
import moment from "moment";

const SessionTimeout = () => {
  const [events, setEvents] = useState(["click", "load", "scroll"]);
  const [second, setSecond] = useState(0);
  const [isOpen, setOpen] = useState(false);
  const [authState, setauthState] = useState(true);

  let timeStamp;
  let warningInactiveInterval = useRef();
  let startTimerInterval = useRef();

  // start inactive check
  let timeChecker = () => {
    startTimerInterval.current = setTimeout(() => {
      let storedTimeStamp = sessionStorage.getItem("lastTimeStamp");
      warningInactive(storedTimeStamp);
    }, 1000);
  };

  // warning timer
  let warningInactive = (timeString) => {
    clearTimeout(startTimerInterval.current);

    warningInactiveInterval.current = setInterval(() => {
      const maxTime = 20;
      const popTime = 10;

      const diff = moment.duration(
        moment().diff(moment(timeString).format("YYYY-MM-DD HH:mm:ss"))
      );
      const minPast = diff.seconds();
      const leftSecond = 60 - diff.seconds();
      console.log(leftSecond);

      if (minPast === popTime) {
        setSecond(leftSecond);
        setOpen(true);
      }

      if (minPast === maxTime) {
        clearInterval(warningInactiveInterval.current);
        setOpen(false);
        sessionStorage.removeItem("lastTimeStamp");
        localStorage.clear();
        window.location.reload();
      }
    }, 1000);
  };

  // reset interval timer
  let resetTimer = useCallback((event) => {
    clearTimeout(startTimerInterval.current);
    clearInterval(warningInactiveInterval.current);
    timeStamp = moment().format("YYYY-MM-DD HH:mm:ss");
    sessionStorage.setItem("lastTimeStamp", timeStamp);
    sessionStorage.setItem("secconds", second);
    console.log(event);
    timeChecker();
    setOpen(false);
  });

  // handle close popup
  const handleClose = () => {
    setOpen(false);
    resetTimer();
  };

  useEffect(() => {
    events.forEach((event) => {
      window.addEventListener(event, resetTimer);
    });

    timeChecker();

    return () => {
      clearTimeout(startTimerInterval.current);
      resetTimer();
    };
  }, [resetTimer, events, timeChecker]);

  console.log(second);

  if (!isOpen) {
    return null;
  }

  // change fragment to modal and handleclose func to close
  return <Fragment />;
};

export default SessionTimeout;
