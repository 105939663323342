import React, { useEffect, useState, Component, useRef } from "react";
import { Redirect } from "react-router-dom";
import "./LoadButtons.css";
import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import { Link } from "react-router-dom";

export default function LoadButtons(buttons) {
  const [inputValues, setInputValues] = useState({});
  const [loadedOptions, setLoadedOpctions] = useState(true);
  const [show, setShow] = useState({
    item: false,
  });
  const target = useRef(null);
  const numbers = ["ele1", "ele2", "ele3"];
  const handleChange = ({ target }) => {
    console.log(target);
    setInputValues({
      ...inputValues,
      [target.name]: target.value,
    });
  };

  const botonesDesagrup = buttons["btn"].botones;
  if (typeof botonesDesagrup != "undefined") {
    return Object.keys(botonesDesagrup).map((item, key) => {
      if (item != "GROUPED") {
        if (botonesDesagrup[item].RInternal == "") {
          return (
            <div
              className="icono-total"
              key={"iconTotal" + botonesDesagrup[item].bID}
            >
              <div className="card-home modal-content-home">
                <a href={botonesDesagrup[item].LINK.replace('"', "")}>
                  <div
                    className="icono"
                    dangerouslySetInnerHTML={{
                      __html: botonesDesagrup[item].ICON,
                    }}
                  />
                  <div className="icono-texto">
                    <h2 className="card-title">
                      {botonesDesagrup[item].bNOMBRE}
                    </h2>
                    <p className="card-text">
                      {botonesDesagrup[item].bDESCRIPCION}
                    </p>
                  </div>
                </a>
              </div>
            </div>
          );
        } else {
          return (
            <div
              className="icono-total"
              key={"iconTotal" + botonesDesagrup[item].bID}
            >
              <div className="card-home modal-content-home">
                <Link to={"/dashboard/" + botonesDesagrup[item].RInternal}>
                  <div
                    className="icono"
                    dangerouslySetInnerHTML={{
                      __html: botonesDesagrup[item].ICON,
                    }}
                  />
                  <div className="icono-texto">
                    <h2 className="card-title">
                      {botonesDesagrup[item].bNOMBRE}
                    </h2>
                    <p className="card-text">
                      {botonesDesagrup[item].bDESCRIPCION}
                    </p>
                  </div>
                </Link>
              </div>
            </div>
          );
        }
      } else {
        return Object.keys(botonesDesagrup["GROUPED"]).map((item2, key2) => {
          const grupo = botonesDesagrup["GROUPED"][item2];
          return (
            <OverlayTrigger
              trigger="click"
              key={buttons["btn"].sID + item2 + "overlay"}
              placement="auto"
              flip
              overlay={
                <Popover
                  id={`popover-positioned-${buttons["btn"].sID + item2}`}
                >
                  <Popover.Header as="h3">
                    {botonesDesagrup["GROUPED"][item2].gNOMBRE}
                  </Popover.Header>
                  <Popover.Body>
                    {Object.keys(grupo).map((item4, key4) => {
                      if (typeof grupo[key4] != "undefined") {
                        if (grupo[key4].RInternal == "") {
                          return (
                            <a
                              href={grupo[key4].LINK.replace('"', "")}
                              key={buttons["btn"].sID + "key" + grupo[key4].bID}
                            >
                              <Button
                                className="col-12 btn-block mr-1 mt-1 btn-warning"
                                key={"btnkey" + grupo[key4].bID}
                              >
                                {grupo[key4].bNOMBRE}
                              </Button>
                            </a>
                          );
                        } else {
                          return (
                            <Link
                              to={"/dashboard/" + grupo[key4].RInternal}
                              key={buttons["btn"].sID + "key" + grupo[key4].bID}
                            >
                              <Button
                                className="col-12 btn-block mr-1 mt-1 btn-warning"
                                key={"btnkey" + grupo[key4].bID}
                              >
                                {grupo[key4].bNOMBRE}
                              </Button>
                            </Link>
                          );
                        }
                      }
                    })}
                  </Popover.Body>
                </Popover>
              }
            >
              <div className="icono-total" key={"iconTotalGroup" + item2}>
                <div className="card-home modal-content-home">
                  <a>
                    <div
                      className="icono"
                      dangerouslySetInnerHTML={{
                        __html: botonesDesagrup["GROUPED"][item2].gICON,
                      }}
                    />
                    <div className="icono-texto">
                      <h2 className="card-title">
                        {botonesDesagrup["GROUPED"][item2].gNOMBRE}
                      </h2>
                      <p className="card-text">
                        {botonesDesagrup["GROUPED"][item2].gDESCRIPCION}
                      </p>
                    </div>
                  </a>
                </div>
              </div>
            </OverlayTrigger>
          );
        });
      }
    });
  } else {
    return <Redirect to="/" />;
  }
}
