import React, { useState, useRef, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import Async, { useAsync } from "react-select/async";
import { Form, InputGroup } from "react-bootstrap";
import { PostData } from "../../Services/PostData";
import LoadingSpinner from "../../Assets/LoadingSpinner/LoadingSpinner";
import "./ContaForm.css";

function ContaForm() {
  const [inputValues, setInputValues] = useState({});
  const [loadedOptions, setLoadedOptions] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState();
  const [startDate, setStartDate] = useState(new Date());
  const [listEESS, setListEESS] = useState([]);
  const [causasOption, setCausasOption] = useState([]);
  const [marcaOptions, setMarcaOptions] = useState([]);
  const [productoOption, setProductoOption] = useState([]);
  const [tipoOption, setTipoOption] = useState([]);
  const [resolucionOption, setResolucionOption] = useState([]);
  const [sancionOption, setSancionOption] = useState([]);
  const [searchMsg, setSearchMsg] = useState("Escriba al menos 3 caracteres");
  const [enviada, setEnviada] = useState("");
  function handleSelect(data, sel) {
    setSelectedOptions(data);
    setInputValues({
      ...inputValues,
      [sel]: data.value,
    });
  }

  useEffect(() => {
    const funcion = {};
    funcion.Function = "Contaminaciones";
    funcion.RequestType = "Request";
    funcion.Access = "CONTAMINACIONES";
    funcion.Event = "getContaminacionOptions";
    funcion.Data = {};
    funcion.usrToken = localStorage.getItem("usrToken");

    PostData("POST", funcion).then((result) => {
      let listEESSAux = [];
      result.eess.forEach((element, key) => {
        listEESSAux.push({
          value: `${result.eess[key].EESS}`,
          label: `${result.eess[key].EESS}`,
        });
      });
      setListEESS(listEESSAux);
      let causasOptionAux = [];
      result.causas.forEach((element, key) => {
        causasOptionAux.push({
          value: `${result.causas[key].ID}`,
          label: `${result.causas[key].causa}`,
        });
      });
      setCausasOption(causasOptionAux);
      let marcaOptionsAux = [];
      result.marcas.forEach((element, key) => {
        marcaOptionsAux.push({
          value: `${result.marcas[key].ID}`,
          label: `${result.marcas[key].marca}`,
        });
      });
      setMarcaOptions(marcaOptionsAux);
      let productoOptionAux = [];
      result.productos.forEach((element, key) => {
        productoOptionAux.push({
          value: `${result.productos[key].ID}`,
          label: `${result.productos[key].producto}`,
        });
      });
      setProductoOption(productoOptionAux);

      let tipoOptionAux = [];
      result.tvehi.forEach((element, key) => {
        tipoOptionAux.push({
          value: `${result.tvehi[key].ID}`,
          label: `${result.tvehi[key].tipo}`,
        });
      });
      setTipoOption(tipoOptionAux);

      let resolucionOptionAux = [];
      result.resoluciones.forEach((element, key) => {
        resolucionOptionAux.push({
          value: `${result.resoluciones[key].ID}`,
          label: `${result.resoluciones[key].resolucion}`,
        });
      });
      setResolucionOption(resolucionOptionAux);

      let sancionOptionAux = [];
      result.sanciones.forEach((element, key) => {
        sancionOptionAux.push({
          value: `${result.sanciones[key].ID}`,
          label: `${result.sanciones[key].sancion}`,
        });
      });

      setSancionOption(sancionOptionAux);

      setInputValues({
        ...inputValues,
        identificada: false,
        firma: false,
        sancionesPrev: false,
      });

      setLoadedOptions(true);
    }, []);
  }, []);

  const handleChange = ({ target }) => {
    if (target.type === "checkbox") {
      setInputValues({
        ...inputValues,
        [target.name]: target.checked,
      });
    } else {
      setInputValues({
        ...inputValues,
        [target.name]: target.value,
      });
    }
  };

  const anoOpciones = [];
  for (let i = new Date().getFullYear(); i >= 1950; i--) {
    anoOpciones.push({ value: `${i}`, label: `${i}` });
  }
  const funcion = {};
  const promiseOptions = (inputValue) => {
    if (inputValue.length > 2 && inputValue.length < 10) {
      return new Promise((resolve, reject) => {
        funcion.Function = "Contaminaciones";
        funcion.RequestType = "Request";
        funcion.Access = "CONTAMINACIONES";
        funcion.Event = "getEmpleados";
        funcion.Data = { inputValue };
        funcion.usrToken = localStorage.getItem("usrToken");

        if (inputValue.length > 2 && inputValue.length < 10) {
          //console.log("aceptado");
          PostData("POST", funcion).then((result) => {
            resolve(result);
          }, []);
        } else {
          reject(new Error("Something is not right!"));
        }
      });
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(inputValues);
    const funcion = {};
    funcion.Function = "Contaminaciones";
    funcion.RequestType = "Request";
    funcion.Access = "CONTAMINACIONES";
    funcion.Event = "postContaminacion";
    funcion.Data = { inputValues };
    funcion.usrToken = localStorage.getItem("usrToken");
    setLoadedOptions(false);
    PostData("POST", funcion).then((result) => {
      result.status != undefined && result.status == 200
        ? setEnviada("correcto")
        : setEnviada("error");
      setInputValues({});
      setLoadedOptions(true);
    }, []);
  };
  function divEnviada() {
    if (enviada == "correcto") {
      return (
        <>
          <div
            style={{
              maxWidth: "400px",
              margin: "auto",
              padding: "10px",
              border: "solid",
              borderWidth: "1px",
              borderColor: "#999",
              borderRadius: "10px",
            }}
          >
            Enviada correctamente
          </div>
        </>
      );
    } else if (enviada == "error") {
      return (
        <>
          <div
            style={{
              maxWidth: "400px",
              margin: "auto",
              padding: "10px",
              border: "solid",
              borderWidth: "1px",
              borderColor: "#999",
              borderRadius: "10px",
            }}
          >
            Error en el envio de la información
          </div>
        </>
      );
    }
  }
  if (loadedOptions) {
    return (
      <>
        <div>{divEnviada()}</div>
        <Form
          onSubmit={handleSubmit}
          style={{
            maxWidth: "400px",
            margin: "auto",
            padding: "10px",
            border: "solid",
            borderWidth: "1px",
            borderColor: "#999",
            borderRadius: "10px",
          }}
        >
          <InputGroup className="mb-3">
            <Select
              className="form-control"
              options={listEESS}
              placeholder="EESS"
              onChange={(e) => {
                handleSelect(e, "eess");
              }}
              id="eess"
              isSearchable={true}
              required
            />
            <input
              tabIndex={-1}
              autoComplete="off"
              style={{
                opacity: 0,
                width: "100%",
                height: 0,
                position: "absolute",
                bottom: "0px",
              }}
              value={inputValues.eess != undefined ? inputValues.eess : ""}
              required
              onChange={() => {}}
            />
          </InputGroup>
          <InputGroup className="mb-3">
            <InputGroup.Text id="basic-addon1">Ticket N°</InputGroup.Text>
            <Form.Control
              placeholder="PV"
              aria-label="PV"
              id="pv"
              type="number"
              aria-describedby="basic-addon1"
              onChange={handleChange}
              name="pv"
              required
            />
            <Form.Control
              placeholder="Comprobante"
              aria-label="Ticket"
              id="ticket"
              name="ticket"
              aria-describedby="basic-addon1"
              onChange={handleChange}
              type="number"
              required
            />
          </InputGroup>
          <InputGroup className="mb-3">
            <InputGroup.Text id="basic-addon1">Fehca y Hora</InputGroup.Text>
            <Form.Control
              type="date"
              id="fechaCont"
              onChange={handleChange}
              name="fechaCont"
              required
            />
            <Form.Control
              type="time"
              id="horaCont"
              onChange={handleChange}
              name="hora"
              required
            />
          </InputGroup>
          <InputGroup className="mb-3">
            <Select
              className="form-control"
              options={marcaOptions}
              id="marca"
              name="marca"
              placeholder="Marca del Auto"
              onChange={(e) => {
                handleSelect(e, "marca");
              }}
              isSearchable={true}
            />{" "}
            <input
              tabIndex={-1}
              autoComplete="off"
              style={{
                opacity: 0,
                width: "100%",
                height: 0,
                position: "absolute",
                bottom: "0px",
              }}
              value={inputValues.marca != undefined ? inputValues.marca : ""}
              required
              onChange={() => {}}
            />
          </InputGroup>
          <InputGroup className="mb-3">
            <Select
              className="form-control"
              options={anoOpciones}
              id="year"
              name="year"
              placeholder="Año del Vehiculo"
              onChange={(e) => {
                handleSelect(e, "year");
              }}
              isSearchable={true}
            />{" "}
            <input
              tabIndex={-1}
              autoComplete="off"
              style={{
                opacity: 0,
                width: "100%",
                height: 0,
                position: "absolute",
                bottom: "0px",
              }}
              value={inputValues.year != undefined ? inputValues.year : ""}
              required
              onChange={() => {}}
            />
          </InputGroup>
          <InputGroup className="mb-3">
            <InputGroup.Text id="basic-addon1">Modelo</InputGroup.Text>
            <Form.Control
              placeholder="Modelo"
              aria-label="Username"
              aria-describedby="basic-addon1"
              id="modelo"
              name="modelo"
              onChange={handleChange}
              required
            />
          </InputGroup>
          <InputGroup className="mb-3">
            <Form.Check
              type="switch"
              label="¿Estaba la tapa identificada?"
              id="identificada"
              name="identificada"
              onChange={handleChange}
            />
          </InputGroup>
          <InputGroup className="mb-3">
            <Select
              className="form-control"
              options={causasOption}
              placeholder="Causa de la contaminacion"
              onChange={(e) => {
                handleSelect(e, "causa");
              }}
              isSearchable={true}
            />
            <input
              tabIndex={-1}
              autoComplete="off"
              style={{
                opacity: 0,
                width: "100%",
                height: 0,
                position: "absolute",
                bottom: "0px",
              }}
              value={inputValues.causa != undefined ? inputValues.causa : ""}
              required
              onChange={() => {}}
            />
          </InputGroup>
          <InputGroup className="mb-3">
            <InputGroup.Text id="basic-addon1">
              Comentarios Adicionales
            </InputGroup.Text>
            <Form.Control
              as="textarea"
              placeholder="Comentarios"
              aria-label="Comentarios"
              aria-describedby="basic-addon1"
              id="comentarios"
              name="comentarios"
              onChange={handleChange}
            />
          </InputGroup>
          <InputGroup className="mb-3">
            <Select
              className="form-control"
              options={productoOption}
              placeholder="Producto Cargado"
              onChange={(e) => {
                handleSelect(e, "producto");
              }}
              isSearchable={true}
            />
            <input
              tabIndex={-1}
              autoComplete="off"
              style={{
                opacity: 0,
                width: "100%",
                height: 0,
                position: "absolute",
                bottom: "0px",
              }}
              value={
                inputValues.producto != undefined ? inputValues.producto : ""
              }
              required
              onChange={() => {}}
            />
          </InputGroup>
          <InputGroup className="mb-3">
            <Select
              className="form-control"
              options={tipoOption}
              placeholder="Tipo de Vehiculo"
              onChange={(e) => {
                handleSelect(e, "tipo");
              }}
              isSearchable={true}
            />
            <input
              tabIndex={-1}
              autoComplete="off"
              style={{
                opacity: 0,
                width: "100%",
                height: 0,
                position: "absolute",
                bottom: "0px",
              }}
              value={inputValues.tipo != undefined ? inputValues.tipo : ""}
              required
              onChange={() => {}}
            />
          </InputGroup>
          <InputGroup className="mb-3">
            <Select
              className="form-control"
              options={resolucionOption}
              placeholder="Resolucion"
              onChange={(e) => {
                handleSelect(e, "resolucion");
              }}
              isSearchable={true}
            />
            <input
              tabIndex={-1}
              autoComplete="off"
              style={{
                opacity: 0,
                width: "100%",
                height: 0,
                position: "absolute",
                bottom: "0px",
              }}
              value={
                inputValues.resolucion != undefined
                  ? inputValues.resolucion
                  : ""
              }
              onChange={() => {}}
              required
            />
          </InputGroup>
          <InputGroup className="mb-3">
            <InputGroup.Text>Gastos</InputGroup.Text>
            <InputGroup.Text>$</InputGroup.Text>
            <Form.Control
              aria-label="Amount (to the nearest dollar)"
              id="pesos"
              name="pesos"
              type="number"
              onChange={handleChange}
              required
            />
            <InputGroup.Text>.</InputGroup.Text>
            <Form.Control
              aria-label="Amount (to the nearest dollar)"
              id="centavos"
              name="centavos"
              onChange={handleChange}
              required
              type="number"
            />
          </InputGroup>
          <InputGroup className="mb-3">
            <Form.Check
              type="switch"
              label="¿Cliente firmo?"
              id="firma"
              name="firma"
              onChange={handleChange}
            />
          </InputGroup>
          <InputGroup className="mb-3">
            <AsyncSelect
              defaultOptions
              menuPlacement="top"
              loadOptions={promiseOptions}
              className="form-control"
              onChange={(e) => {
                handleSelect(e, "empleado");
              }}
              loadingMessage={() => searchMsg}
              noOptionsMessage={() => "Sin resultados"}
              onInputChange={(data) => {
                if (data.length > 2) {
                  setSearchMsg("Buscando...");
                } else {
                  setSearchMsg("Escriba al menos 3 caracteres");
                  // console.log("pocos");
                }
              }}
            />
            <input
              tabIndex={-1}
              autoComplete="off"
              style={{
                opacity: 0,
                width: "100%",
                height: 0,
                position: "absolute",
                bottom: "0px",
              }}
              value={
                inputValues.empleado != undefined ? inputValues.empleado : ""
              }
              required
              onChange={() => {}}
            />
          </InputGroup>
          <InputGroup className="mb-3">
            <Form.Check
              type="switch"
              label="Sanciones previas"
              id="sancionesPrev"
              name="sancionesPrev"
              onChange={handleChange}
            />
          </InputGroup>
          <InputGroup className="mb-3">
            <Select
              className="form-control"
              options={sancionOption}
              placeholder="Sancion Aplicada"
              onChange={(e) => {
                handleSelect(e, "sancion");
              }}
              menuPlacement="top"
              isSearchable={true}
            />
            <input
              tabIndex={-1}
              autoComplete="off"
              style={{
                opacity: 0,
                width: "100%",
                height: 0,
                position: "absolute",
                bottom: "0px",
              }}
              value={
                inputValues.sancion != undefined ? inputValues.sancion : ""
              }
              required
              onChange={() => {}}
            />
          </InputGroup>
          <InputGroup className="mb-3">
            <Button className="btn-danger col-12" type="submit">
              Enviar
            </Button>
          </InputGroup>
        </Form>
      </>
    );
  } else {
    return <LoadingSpinner />;
  }
}

export default ContaForm;
