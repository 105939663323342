import axios from "axios";
import { Redirect } from "react-router-dom";
export function PostData(type, userData) {
  const isLocalhost = Boolean(
    window.location.hostname === "localhost" ||
      window.location.hostname === "localhost:3000" ||
      // [::1] is the IPv6 localhost address.
      window.location.hostname === "[::1]" ||
      // 127.0.0.0/8 are considered localhost for IPv4.
      window.location.hostname.match(
        /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
      )
  );
  let BaseURL = "";
  if (isLocalhost) {
    BaseURL = "http://127.0.0.1:8080/";
  } else if(process.env.REACT_APP_SERVER == "DEV") {
    BaseURL = "https://devapi.deheza.ar/";
  }else{
    BaseURL = "https://api.deheza.ar/";
  }
  return new Promise((resolve, reject) => {
    fetch(BaseURL, {
      method: type,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        method: type,
      },
      body: JSON.stringify(userData),
    })
      .then((response) =>
        response.json().then((res) => {
          if (res.Respuesta == 3) {
            localStorage.clear();
            window.location.href = "/";
          }
          resolve(res);
        })
      )
      .catch((error) => {
        reject(error);
        //console.log(error);
      });
  });

  return new Promise((resolve, reject) => {
    // With all properties
    let body = {
      userId: 1111,
      title: "This is POST request with body",
      completed: true,
    };
    axios
      .post("https://jsonplaceholder.typicode.com/todos", body)
      .then(function (response) {
        console.log(response.data);
        resolve(response);
      })
      .catch(function (error) {
        console.log(error);
        reject(error);
      });
  });
}
