import React, { Component, useState, useEffect } from "react";
import LoadingSpinner from "../../../Assets/LoadingSpinner/LoadingSpinner";
import { PostData } from "../../../Services/PostData";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { Pagination } from "react-bootstrap";
import { Link } from "react-router-dom/cjs/react-router-dom";

export default function Blog() {
  const [news, setNews] = useState({});
  const [uid, setUid] = useState("");
  const [total, setTotal] = useState(0);
  const [items, setItems] = useState([]);
  const routeParams = useParams();
  const [limit, setLimit] = useState(5);
  const [isLoading, setIsLoading] = useState(true);
  const [number, setNumber] = useState(1);
  let start = 0;
  useEffect(() => {
    if (routeParams.id != undefined) {
      start = parseInt(routeParams.id) - 1;
    }
    const funcion = {};
    funcion.Function = "getNews";
    funcion.RequestType = "Request";
    funcion.Event = "NEWS";
    funcion.usrToken = localStorage.getItem("usrToken");
    funcion.data = { limit: 10, offset: start };
    PostData("POST", funcion).then((result) => {
      setTotal(result.total);
      delete result.total;
      setNews(result);
      setIsLoading(false);
      let active = parseInt(routeParams.id);
      let nPages = Math.ceil(total / 10);
      if (routeParams.id != undefined && routeParams.id >= 3) {
        setNumber(parseInt(routeParams.id) - 2);
        setLimit(parseInt(routeParams.id) + 2);
        start = parseInt(routeParams.id) - 1;
      } else if (routeParams.id != undefined && routeParams.id < 3) {
        setNumber(1);
        setLimit(5);
        start = parseInt(routeParams.id) - 1;
      }
      if (limit > nPages) {
        setLimit(nPages);
      }
      let n = number;
      for (n; n <= limit; n++) {
        setItems((items) => [
          ...items,
          <Pagination.Item
            key={n}
            active={n == active}
            href={"/dashboard/blog/" + n}
          >
            {n}
          </Pagination.Item>,
        ]);
      }
    }, []);
  }, []);

  if (isLoading) {
    return (
      <div>
        <h1>Noticias</h1>
        <LoadingSpinner />
      </div>
    );
  } else {
    return (
      <>
        <h1>Noticias</h1>
        {Object.keys(news).map((key) => {
          return (
            <div key={`row` + key} className="container">
              <Link to={`/dashboard/blog/post/${news[key].UID}`}>
                <div className="row">{news[key].created}</div>
                <div className="row">
                  {news[key].postImg == "" || news[key].postImg == undefined ? (
                    <>
                      <div className="row">{news[key].newTitle}</div>
                      <div
                        className="row"
                        dangerouslySetInnerHTML={{ __html: news[key].newBody }}
                      ></div>
                    </>
                  ) : (
                    <>
                      <div div className="row">
                        <img
                          src={news[key].postImg}
                          className="postMiniature"
                        />
                      </div>
                      <div className="row">{news[key].postTitle}</div>
                      <div className="row">{news[key].postCopy}</div>
                    </>
                  )}
                  <>Ver más...</>
                </div>
              </Link>
            </div>
          );
        })}

        <Pagination className="paginationNumbers">
          <Pagination.First />
          <Pagination.Prev />
          {items}
          <Pagination.Next />
          <Pagination.Last />
        </Pagination>
      </>
    );
  }
}
