import React, { useEffect, useState } from "react";
import { models, Report, Embed, service, Page } from "powerbi-client";
import { PowerBIEmbed } from "powerbi-client-react";
import { PostData } from "../../Services/PostData";
import "./PBI.css";
import LoadingSpinner from "../../Assets/LoadingSpinner/LoadingSpinner";

function ReportePBI(props) {
  useEffect(() => {
    setHash("");
    setAppState(false);
  }, [window.location.hash]);

  const [reportParams, setReportParams] = useState({});
  const funcion = {};
  const home = [];
  const [hash, setHash] = useState("");
  const [appState, setAppState] = useState(false);
  document.title = "PowerBI";
  useEffect(() => {
    funcion.Function = "PBIReport";
    funcion.RequestType = "Request";
    funcion.usrToken = localStorage.getItem("usrToken");
    funcion.Report = window.location.hash;
    PostData("POST", funcion).then((result) => {
      setReportParams(result);
      setAppState(true);
      setHash(result.name);
    }, []);
  }, [hash]);

  if (appState == true) {
    if (typeof reportParams.name != "undefined") {
      document.title = "Power BI - " + reportParams.name;
    }
    if (reportParams.embedType == "ServicePrincipal") {
      return (
        <PowerBIEmbed
          embedConfig={{
            type: "report", // Supported types: report, dashboard, tile, visual and qna
            id: reportParams.reportConfig["0"].reportId,
            embedUrl: reportParams.reportConfig["0"].embedUrl,
            accessToken: reportParams.accessToken,
            tokenType: models.TokenType.Embed,
            pageName: reportParams.reportConfig["0"].pageName,
            settings: {
              panes: {
                filters: {
                  expanded: false,
                  visible: false,
                },
                pageNavigation: {
                  visible: JSON.parse(
                    reportParams.reportConfig["0"].pageNavigation.toLowerCase()
                  ),
                },
              },
              background: models.BackgroundType.Transparent,
            },
          }}
          eventHandlers={
            new Map([
              [
                "loaded",
                function () {
                  console.log("Report loaded");
                },
              ],
              [
                "rendered",
                function () {
                  console.log("Report rendered");
                },
              ],
              [
                "error",
                function (event) {
                  console.log(event.detail);
                },
              ],
            ])
          }
          cssClassName={"report-style-class"}
          getEmbeddedComponent={(embeddedReport) => {
            window.report = embeddedReport;
          }}
        />
      );
    } else if (reportParams.embedType == "Secure") {
      return (
        <iframe
          src={
            "https://app.powerbi.com/reportEmbed?reportId=" +
            reportParams.reportConfig["0"].reportId +
            "&autoAuth=true&ctid=423d733c-d9e1-447b-b6ed-22456af2dd16&pageName=" +
            reportParams.reportConfig["0"].pageName
          }
          width={"100%"}
          height={"99%"}
        />
      );
    } else if (reportParams.Respuesta == 5) {
      return <div>Acceso no autorizado {JSON.stringify(reportParams)}</div>;
    } else {
      return (
        <>Parece que ha ocurrido un error. {JSON.stringify(reportParams)}</>
      );
    }
  } else {
    return <LoadingSpinner />;
  }
}

export default ReportePBI;
