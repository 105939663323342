import { Pagination, Table } from "react-bootstrap";
import { Pencil, Trash } from "react-bootstrap-icons";
import { PostData } from "../../../Services/PostData";
import LoadingSpinner from "../../../Assets/LoadingSpinner/LoadingSpinner";
import { Link } from "react-router-dom/cjs/react-router-dom";
import "./BlogAdmin.css";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useState, useEffect } from "react";
import { Button } from "react-bootstrap";

export default function BlogAdmin() {
  const [news, setNews] = useState({});
  const [uid, setUid] = useState("");
  const [total, setTotal] = useState(0);
  const [items, setItems] = useState([]);
  const routeParams = useParams();
  const [limit, setLimit] = useState(5);

  const [number, setNumber] = useState(1);
  //TODO #17 corregir el bug de la paginacion
  let start = 0;
  const deletePost = (id, key, e) => {
    const funcion = {};
    funcion.Function = "deleteNews";
    funcion.RequestType = "Post";
    funcion.Event = "NEWSADMIN";
    funcion.usrToken = localStorage.getItem("usrToken");
    funcion.data = { id: id };
    PostData("POST", funcion).then((result) => {
      if (result.Respuesta == 200) {
        window.location.reload();
      }
    });
  };
  useEffect(() => {
    if (routeParams.id != undefined) {
      start = parseInt(routeParams.id) - 1;
    }
    const funcion = {};
    funcion.Function = "getAdminNews";
    funcion.RequestType = "Request";
    funcion.Event = "NEWSADMIN";
    funcion.usrToken = localStorage.getItem("usrToken");
    funcion.data = { limit: 10, offset: start };
    PostData("POST", funcion).then((result) => {
      setTotal(result.total);
      delete result.total;
      setNews(result);
      setIsLoading(false);
      let active = parseInt(routeParams.id);
      let nPages = Math.ceil(total / 10);
      if (routeParams.id != undefined && routeParams.id >= 3) {
        setNumber(parseInt(routeParams.id) - 2);
        setLimit(parseInt(routeParams.id) + 2);
        start = parseInt(routeParams.id) - 1;
      } else if (routeParams.id != undefined && routeParams.id < 3) {
        setNumber(1);
        setLimit(5);
        start = parseInt(routeParams.id) - 1;
      }
      if (limit > nPages) {
        setLimit(nPages);
      }
      let n = number;
      for (n; n <= limit; n++) {
        setItems((items) => [
          ...items,
          <Pagination.Item
            key={n}
            active={n == active}
            href={"/dashboard/blog/admin/" + n}
          >
            {n}
          </Pagination.Item>,
        ]);
      }
    }, []);
  }, []);

  const [isLoading, setIsLoading] = useState(true);
  return isLoading ? (
    <LoadingSpinner />
  ) : (
    <div className="blogAdmin">
      <Link to="/dashboard/blog/admin/edit">
        <Button>Nueva Noticia</Button>
      </Link>
      <div className="postTable">
        <Table striped bordered hover>
          <thead>
            <tr className="tableHeader">
              <th>#</th>
              <th>Fecha</th>
              <th>Post</th>
              <th>Titulo Post</th>
              <th>Copy Post</th>
              <th>Titulo Noticia </th>
              <th>Noticia</th>
              <th>Accion</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(news).map((key) => {
              return (
                <tr key={`row` + key}>
                  <td>{news[key].UID}</td>
                  <td>{news[key].created}</td>
                  <td>
                    {news[key].postImg != "" ? (
                      <img src={news[key].postImg} className="postMiniature" />
                    ) : (
                      <></>
                    )}
                  </td>
                  <td>{news[key].postTitle}</td>
                  <td>{news[key].postCopy}</td>
                  <td>{news[key].newTitle}</td>
                  <td>
                    <div
                      dangerouslySetInnerHTML={{ __html: news[key].newBody }}
                    ></div>
                  </td>
                  <td>
                    <Link to={"admin/edit/" + news[key].UID}>
                      <Pencil />
                    </Link>
                    <Trash
                      onClick={(e) => deletePost(news[key].UID, key, e)} //TODO #18 implementar la funcion de eliminar
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
      <Pagination className="paginationNumbers">
       {/* 
       <Pagination.First />
        <Pagination.Prev />
          */}{items}
        {/*
        <Pagination.Next />
        <Pagination.Last />
        */}
      </Pagination>
    </div>
  );
}
