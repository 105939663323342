import React, { Component, useState, useContext, useEffect } from "react";
import { Redirect, Switch, Route, Link } from "react-router-dom";

import { withRouter } from "react-router";
import "bootstrap/dist/css/bootstrap.min.css";
import HomeButtons from "./Components/Home/HomeButtons/HomeButtons";
import NavigationBar from "./Components/Nav/Nav";
import { PostData } from "./Services/PostData";
import ReportePBI from "./Components/PBI/PBI";
import BlogEdit from "./Components/Blog/BlogAdmin/BlogEdit/BlogEdit";
import BlogAdmin from "./Components/Blog/BlogAdmin/BlogAdmin";
import Capacitaciones from "./Components/Capacitaciones/Capacitaciones";
import ContaForm from "./Components/ContaForm/ContaForm";
import HomeNews from "./Components/Home/HomeNews/HomeNews";
import Blog from "./Components/Blog/BlogHome/Blog";
import BlogPost from "./Components/Blog/BlogHome/Post";
import LoadingSpinner from "./Assets/LoadingSpinner/LoadingSpinner";
import BackgroundImageOnLoad from "background-image-on-load";
import bgImg from "./Assets/Images/Login/img_blur.jpg";
import bg1 from "./Assets/Images/Login/bg_1.jpg";
import bg2 from "./Assets/Images/Login/bg_2.jpg";
import bg3 from "./Assets/Images/Login/bg_3.jpg";
import bg4 from "./Assets/Images/Login/bg_4.jpg";
import bg5 from "./Assets/Images/Login/bg_5.jpg";
import bg6 from "./Assets/Images/Login/bg_6.jpg";
import "./Dashboard.css";
import Profile from "./Components/Profile/Profile";
import { Bug, HouseFill } from "react-bootstrap-icons";
import { Button, OverlayTrigger, Popover } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { SigninContext } from "./Context/Context";
import PasswordChange from "./Components/Profile/ChangePassword";
import MP from "./Components/MP/MP.js";

function Dashboard(props) {
  //console.log(process.env.REACT_APP_SERVER);

  const { pwChange, setPWChange, setProfileFill, profileFill } =
    useContext(SigninContext);
  const images = [bg1, bg2, bg3, bg4, bg5, bg6];
  const [bgIsLoaded, setBgIsLoaded] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(
    Math.floor(Math.random() * images.length)
  );
  const [isloading, setIsloading] = useState(true);
  const [islogout, setIslogout] = useState(false);
  const [homeButtons, setHomeButtons] = useState([]);
  const [appName, setAppName] = useState("Deheza");
  const [errorMsj, setErrorMsj] = useState("");
  const [errorSent, setErrorSent] = useState(false);
  const logout = () => {
    localStorage.clear();
    setIslogout(true);
  };

  const handleChange = (e) => {
    setErrorMsj(e.target.value);
  };
  const loaddash = () => {
    if (localStorage.getItem("usrToken") != null) {
      const funcion = {};
      funcion.Function = "homeButtons";
      funcion.RequestType = "Request";
      funcion.usrToken = localStorage.getItem("usrToken");
      PostData("POST", funcion).then((result) => {
        if (typeof result.Respuesta == "undefined") {
          localStorage.setItem("homeButtons", JSON.stringify(result));
          setIsloading(false);
        }
      }, []);
    }
  };
  useEffect(() => {
    setPWChange(localStorage.getItem("pswChange") == 1 ? true : false);
    setProfileFill(localStorage.getItem("profileFill") == 1 ? true : false);
  }, []);
  const popover = (
    <Popover id="popover-basic">
      <Popover.Header as="h3">Reportar un Error</Popover.Header>
      <Popover.Body>
        <strong>¡Increible que se nos escapara un bug!</strong> Informanos del
        error en el siguiente formulario. Se lo más descriptivo posible.
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            setErrorSent(true);
            const funcion = {};
            funcion.Function = "errorReport";
            funcion.RequestType = "Post";
            funcion.data = {};
            funcion.data.error = errorMsj;
            funcion.data.location = window.location;
            funcion.usrToken = localStorage.getItem("usrToken");
            PostData("POST", funcion).then((result) => {
              setErrorMsj("");
            }, []);
          }}
        >
          {!errorSent ? (
            <>
              <Form.Group className="mb-3">
                <Form.Label id="basic-addon1">Descripción del Error</Form.Label>
                <Form.Control
                  as="textarea"
                  value={errorMsj}
                  rows={3}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group>
                <Button variant="primary" type="submit">
                  Enviar
                </Button>
              </Form.Group>
            </>
          ) : (
            <div className="alert alert-success" role="alert">
              ¡Gracias por tu ayuda! Nos permitira mejorar el sitio.
            </div>
          )}
        </Form>
      </Popover.Body>
    </Popover>
  );
  if (islogout) {
    return <Redirect to="/" />;
  } else {
    if (isloading) {
      if (localStorage.getItem("homeButtons") === null) {
        loaddash();
      } else {
        setIsloading(false);
        setHomeButtons(JSON.parse(localStorage.getItem("homeButtons")));
      }
      return <LoadingSpinner />;
    } else {
      document.title = "Dashboard";
      const { match } = props;
      const botones = homeButtons;
      <div className="background-img"></div>;
      return (
        <div id="outer-container" className="dashboard">
          <NavigationBar logout={logout} data={botones} />

          <main role="main" id="page-wrap">
            <div className="solidbar">
              <div className="homeIcon" ><Link to={'/'}><HouseFill size={26} /></Link></div>
              <div style={{padding: 4+"px"}}>{process.env.REACT_APP_SERVER}</div>
            </div>

            <div className="dashcontent">
              <div
                className="background-img"
                style={{
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  backgroundImage: `url(${
                    !bgIsLoaded ? bgImg : images[currentImageIndex]
                  })`,
                }}
              >
                <BackgroundImageOnLoad
                  src={images[currentImageIndex]}
                  onLoadBg={() => {
                    setBgIsLoaded(true);
                  }}
                  onError={(err) => console.log("error", err)}
                />
              </div>
              <div className="content-holder">
                {profileFill ? (
                  <Profile isForced={profileFill} />
                ) : pwChange ? (
                  <PasswordChange isForced={pwChange} />
                ) : (
                  <Switch>
                    <Route exact path={`${match.path}/capacitaciones`}>
                      <Capacitaciones />
                    </Route>
                    <Route exact path={`${match.path}/nuevacontaminacion`}>
                      <ContaForm />
                    </Route>
                    <Route strict exact path={`${match.path}/blog`}>
                      <Blog />
                    </Route>
                    <Route strict exact path={`${match.path}/blog/admin`}>
                      <BlogAdmin />
                    </Route>
                    <Route strict exact path={`${match.path}/blog/admin/edit`}>
                      <BlogEdit />
                    </Route>
                    <Route path={`${match.path}/blog/admin/edit/:id`}>
                      <BlogEdit />
                    </Route>
                    <Route strict exact path={`${match.path}/blog/admin/:id`}>
                      <BlogAdmin />
                    </Route>
                    <Route path={`${match.path}/blog/post/:id`}>
                      <BlogPost />
                    </Route>
                    <Route path={`${match.path}/blog/:id`}>
                      <Blog />
                    </Route>
                    <Route
                      exact
                      path={`${match.path}/profile`}
                      isForced={false}
                    >
                      <Profile />
                    </Route>
                    <Route path={`${match.path}/PBI*`}>
                      <ReportePBI />
                    </Route>
                    
                    <Route path={`${match.path}/MP`}>
                      <MP />
                    </Route>
                    <Route
                      exact
                      path={`${match.path}/changepassword`}
                      isForced={false}
                    >
                      <PasswordChange />
                    </Route>
                    <Route exact path={`${match.path}`}>
                      <>
                        <div className="dashboardNews">
                          <HomeNews />
                        </div>
                        <div className="dasboardButton">
                          <HomeButtons data={homeButtons} />
                        </div>
                      </>
                    </Route>
                    <Route path="*">
                      <div>
                        No encontramos lo que estabas buscando. Estamos
                        construyendo asi que puede ser que todavia no este
                        listo!
                      </div>
                    </Route>
                  </Switch>
                )}
              </div>
            </div>
          </main>
          <OverlayTrigger
            trigger="click"
            placement="auto"
            overlay={popover}
            onExit={() => {
              setErrorSent(false);
              setErrorMsj("");
            }}
          >
            <Button variant="danger" className="ayuda-flotante">
              <Bug />
            </Button>
          </OverlayTrigger>
        </div>
      );
    }
  }
}

export default withRouter(Dashboard);
