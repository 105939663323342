import { useParams } from "react-router-dom";
import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { PostData } from "../../Services/PostData";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import "./PasswordReset.css";
import "bootstrap/dist/css/bootstrap.min.css";
import BackgroundImageOnLoad from "background-image-on-load";
import bgImg from "../../Assets/Images/Login/img_blur.jpg";
import bg1 from "../../Assets/Images/Login/bg_1.jpg";
import bg2 from "../../Assets/Images/Login/bg_2.jpg";
import bg3 from "../../Assets/Images/Login/bg_3.jpg";
import bg4 from "../../Assets/Images/Login/bg_4.jpg";
import bg5 from "../../Assets/Images/Login/bg_5.jpg";
import bg6 from "../../Assets/Images/Login/bg_6.jpg";
import { FloatingLabel } from "react-bootstrap";
import LoadingSpinner from "../../Assets/LoadingSpinner/LoadingSpinner";
import { useState } from "react";
import { usePasswordValidation } from "../../Services/PasswordValidate";

const images = [bg1, bg2, bg3, bg4, bg5, bg6];

export default function PasswordReset() {
  const routeParams = useParams();
  const [currentImageIndex, setCurrentImageIndex] = useState(
    Math.floor(Math.random() * images.length)
  );
  const [bgIsLoaded, setBgIsLoaded] = useState(false);
  const [loading, setLoading] = useState(false);

  const [changeStatus, setChangeStatus] = useState("");
  const [password, setPassword] = useState({
    firstPassword: "",
    secondPassword: "",
  });

  const [validLength, hasNumber, upperCase, lowerCase, match, specialChar] =
    usePasswordValidation({
      firstPassword: password.firstPassword,
      secondPassword: password.secondPassword,
      requiredLength: 8,
    });

  const onChange = ({ target }) => {
    setPassword({
      ...password,
      [target.name]: target.value,
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const funcion = {};
    funcion.Function = "resetPasswordLink";
    funcion.Data = {
      password: password.firstPassword,
      link: routeParams.id,
    };

    PostData("POST", funcion).then((result) => {
      typeof result.Respuesta != "undefined"
        ? result.Respuesta == 200
          ? setChangeStatus("success")
          : setChangeStatus("authError")
        : setChangeStatus("authError");
    }, []);
  };

  return (
    <div>
      <div
        className="login-background"
        style={{
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundImage: `url(${
            !bgIsLoaded ? bgImg : images[currentImageIndex]
          })`,
        }}
      >
        <BackgroundImageOnLoad
          src={images[currentImageIndex]}
          onLoadBg={() => {
            setBgIsLoaded(true);
          }}
          onError={(err) => console.log("error", err)}
        />
      </div>
      <div
        className="modal show"
        style={{ display: "block", position: "initial" }}
      >
        <Modal.Dialog className="modal-login">
          <Form onSubmit={handleSubmit}>
            <Modal.Header>
              <div className="avatar">
                <img
                  src="/images/android-icon-72x72.png"
                  alt="Logo Deheza"
                  width={65}
                  height={65}
                />
              </div>
              <Modal.Title className="modresetheader">
                Restablecer contraseña
              </Modal.Title>
            </Modal.Header>
            {loading ? (
              <LoadingSpinner />
            ) : changeStatus == "" ? (
              <>
                <Modal.Body>
                  <Form.Group>
                    <FloatingLabel
                      controlId="floatingInputGriduser"
                      label="Nueva contraseña"
                      required
                    >
                      <Form.Control
                        type="password"
                        className="form-input"
                        name="firstPassword"
                        placeholder="Nueva contraseña"
                        required="required"
                        aria-label="nueva contraseña"
                        onChange={onChange}
                        style={{ marginbottom: 15 }}
                      />
                    </FloatingLabel>
                  </Form.Group>
                  <Form.Group controlId="formBasicPassword">
                    <FloatingLabel
                      controlId="floatingInputGridpass"
                      label="Repetir Contraseña"
                      required
                    >
                      <Form.Control
                        aria-label="repetircontrseña"
                        type="password"
                        className="form-input"
                        name="secondPassword"
                        placeholder="Contraseña"
                        onChange={onChange}
                      />
                    </FloatingLabel>
                  </Form.Group>
                  {password.firstPassword != "" &&
                  !(
                    validLength &&
                    hasNumber &&
                    upperCase &&
                    lowerCase &&
                    match &&
                    specialChar
                  ) ? (
                    <>
                      La contraseña debe tener:
                      <br />
                      {validLength ? (
                        <></>
                      ) : (
                        <span>
                          8 Caracteres
                          <br />
                        </span>
                      )}
                      {hasNumber ? (
                        <></>
                      ) : (
                        <span>
                          1 numero
                          <br />
                        </span>
                      )}
                      {upperCase ? (
                        <></>
                      ) : (
                        <span>
                          1 mayuscula
                          <br />
                        </span>
                      )}
                      {lowerCase ? (
                        <></>
                      ) : (
                        <span>
                          1 minuscula
                          <br />
                        </span>
                      )}
                      {specialChar ? (
                        <></>
                      ) : (
                        <span>
                          1 caracter especial
                          <br />
                        </span>
                      )}
                      {match ? (
                        <></>
                      ) : (
                        <span>
                          Deben coincidir
                          <br />
                        </span>
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                </Modal.Body>

                {validLength &&
                hasNumber &&
                upperCase &&
                lowerCase &&
                match &&
                specialChar ? (
                  <Modal.Footer>
                    <Button
                      variant="warning"
                      type="submit"
                      style={{ color: "#000" }}
                    >
                      Cambiar Contraseña
                    </Button>
                  </Modal.Footer>
                ) : (
                  <></>
                )}
              </>
            ) : changeStatus == "success" ? (
              <>
                {" "}
                ¡Cambio exitoso!
                <br />
                Ya podes iniciar sesion nuevamente con tu nueva contraseña.
              </>
            ) : changeStatus == "authError" ? (
              <>
                Error en el enlace utilizado, el mismo es invalido o ya fue
                utilizado anteriormente.
              </>
            ) : (
              <>
                Ha ocurrido un error general.
                <br />
                Por favor volve a intenter mas tarde
              </>
            )}
          </Form>
        </Modal.Dialog>
      </div>
    </div>
  );
}
