import React, { useEffect, useState } from "react";
import LoadButtons from "./LoadButtons.js";
import Buttons from "./LoadButtons.js";

import "./loadSecctions.css";

export default function Secciones(buttons) {
  return Object.keys(buttons["buttons"]).map((item) => {
    return (
      <div className="appsection align-items-left" key={"sec" + item}>
        <h3 className="apptitle" key={"h3" + item}>
          {buttons["buttons"][item].sNOMBRE}
          <div className="appicons" key={"appsIcon" + item}>
            <LoadButtons key={"btn" + item} btn={buttons["buttons"][item]} />
          </div>
        </h3>
      </div>
    );
  });
}

/*<Nav.Link as={Link} to={`${this.props.path}/blog`}>Blog</Nav.Link>*/
