import React, { useEffect, useState } from "react";
import { PostData } from "../../../Services/PostData";
import Secciones from "./LoadSections";
import LoadingSpinner from "../../../Assets/LoadingSpinner/LoadingSpinner";

function HomeButtons(buttons) {
  const [homeButtons, setHomeButtons] = useState(buttons);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (localStorage.getItem("homeButtons") != null) {
      setLoading(false);
      setHomeButtons(JSON.parse(localStorage.getItem("homeButtons")));
    }
  }, []);
  //console.log(loading);
  if (!loading) {
    return <Secciones buttons={homeButtons} />;
  } else {
    return <LoadingSpinner />;
  }
}
export default HomeButtons;
