import { useEffect, useState } from "react";
import "./Offline.css";
import Modal from "react-bootstrap/Modal";

export default function Offline({ children }) {
  const [isOnline, setIsOnline] = useState(true);
  const [show, setShow] = useState(false);
  useEffect(() => {
    window.addEventListener("online", () => {
      setIsOnline(true);
      setShow(false);
    });
    window.addEventListener("offline", () => {
      setIsOnline(false);
      setShow(true);
    });
  }, []);

  return (
    <>
      <Modal show={show} backdrop="static" keyboard={false} centered>
        <Modal.Header>
          <Modal.Title>Parece que estas sin conexión</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Lamentablemente para poder usar la aplicacion necesitas conexion a
          internet. <br /> No te preocupes, ni bien la recuperes podras
          continuar utilizandola.
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
      {children}
    </>
  );
}
