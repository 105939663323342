import React, { Component, useState, useEffect, useContext } from "react";
import { Button, Form } from "react-bootstrap";
import LoadingSpinner from "../../Assets/LoadingSpinner/LoadingSpinner";
import { PostData } from "../../Services/PostData";
import "./MP.css";
import InputGroup from "react-bootstrap/InputGroup";
import { SigninContext } from "../../Context/Context";
import { Dropdown } from "bootstrap";
//TODO #22 no permite editar el mail
//TODO #23 no pide reingresar el mail/pass desde dashboard
export default function MP(props) {
  /*
  const { pwChange, setPWChange, setProfileFill, profileFill } =
    useContext(SigninContext);
  const [isLoading, setIsLoading] = useState(true);
 
  const funcion = {};
  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    console.log(e.target.email.value);
    funcion.Function = "postUserProfile";
    funcion.RequestType = "Post";
    funcion.usrToken = localStorage.getItem("usrToken");
    funcion.data = { email: profile.email };
    PostData("POST", funcion).then((result) => {
      if (props.isForced != undefined && props.isForced) {
        setProfileFill(false);
      }
      setIsLoading(false);
      setProfile(result);
    }, []).catch((error) => {
      //setNewsTitle("Error de conexión, por favor intente nuevamente.");
      setIsLoading(false);
    });
  };
  */
  /*
  useEffect(() => {
    funcion.Function = "getProfile";
    funcion.RequestType = "Request";
    funcion.usrToken = localStorage.getItem("usrToken");
    funcion.data = {};
    PostData("POST", funcion).then((result) => {
      setIsLoading(false);
      setProfile(result);
    }, []).catch((error) => {
      //setNewsTitle("Error de conexión, por favor intente nuevamente.");
      setIsLoading(false);
    });
  }, []);

  if (isLoading) {*/
  return (
    <div className="qrHolder">
      <h1 className="col-12">Operaciones QR</h1>
      <Form style={{ display: "flex" }}>
        <Form.Group className="qrLookup">
          <Form.Label htmlFor="Select">Medio de Pago</Form.Label>
          <Form.Select aria-label="Default select example">
            <option disabled>Seleccione Tipo de Operacion</option>
            <option value="1">MercadoPago</option>
            <option value="2">ShellBOX</option>
          </Form.Select>
        </Form.Group>
        <Form.Group className="qrLookup">
          <Form.Label htmlFor="checkbox">Tipo de Busqueda</Form.Label>
          <Form.Check
            type="radio"
            name="tipoVista"
            id="disabledFieldsetCheck"
            label="Ver ultimas transacciones"
            defaultChecked={true}
          />
          <Form.Check
            type="radio"
            name="tipoVista"
            id="disabledFieldsetCheck"
            label="Busaca por fecha"
          />
        </Form.Group>
        <InputGroup className="qrLookup">
          <InputGroup.Text id="basic-addon1">Fehca y Hora</InputGroup.Text>
          <Form.Control
            type="date"
            id="fechaCont"
            onChange={() => {}}
            name="fechaCont"
            required
          />
        </InputGroup>

        <InputGroup className="qrLookup">
          <InputGroup.Text id="basic-addon1">Fehca y Hora</InputGroup.Text>
          <Form.Control
            type="date"
            id="fechaCont"
            onChange={() => {}}
            name="fechaCont"
            required
          />
        </InputGroup>
        <Button type="submit">Buscar</Button>
      </Form>

      <LoadingSpinner />
    </div>
  );
  //}
}
