import React from "react";
import { useEffect } from "react";
import Login from "./Components/Login/Login";
import Dashboard from "./Dashboard";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute";
import Offline from "./Offline";
import SessionTimeout from "./Services/SessionTimeout";

import ReactGA from "react-ga";
import PasswordReset from "./Components/PasswordReset/PasswordReset";
import { SigninContext } from "./Context/Context";
import context from "react-bootstrap/esm/AccordionContext";

const TRACKING_ID = "UA-253209804-1"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

export default function App() {
  const [pwChange, setPWChange] = React.useState(1);
  const [profileFill, setProfileFill] = React.useState(1);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  //TODO #20 obligar a cambiar contraseña o completar perfil ya se dispone de las variables globales
  return (
    <Offline>
      <SigninContext.Provider
        value={{ pwChange, setPWChange, profileFill, setProfileFill }}
      >
        <Router>
          <Switch>
            <Route
              path="/reservar"
              component={() => {
                window.location.replace(
                  "https://apps.powerapps.com/play/e/default-423d733c-d9e1-447b-b6ed-22456af2dd16/a/f21d92f9-7a99-453f-9291-482a23dacc54?tenantId=423d733c-d9e1-447b-b6ed-22456af2dd16"
                );
              }}
            />
            <Route
              path="/certificados"
              component={() => {
                window.location.replace(
                  "https://forms.office.com/pages/responsepage.aspx?id=PHM9QuHZe0S27SJFavLdFmwgA0JpLEdPmkIrQfJTmepUOFkwS1Q4WVRJMlpZS1BOQzRKVkNIUlhDUy4u"
                );
              }}
            />
            <Route path="/login">
              <Login />
            </Route>
            <Route path="/passwordreset/:id">
              <PasswordReset />
            </Route>
            <ProtectedRoute path="/dashboard">
              <Dashboard />
            </ProtectedRoute>
            <Route exact path="/">
              <Redirect exact from="/" to="dashboard" />
            </Route>
            <Route path="*">
              <Redirect from="/" to="dashboard" />
            </Route>
          </Switch>
        </Router>
      </SigninContext.Provider>
    </Offline>
  );
}
