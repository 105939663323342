import React, { Component, useEffect, useState, useRef } from "react";
import { Redirect } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { PostData } from "../../../../Services/PostData";
import { Card, Form, InputGroup, Spinner } from "react-bootstrap";
import "./BlogEdit.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import LoadingSpinner from "../../../../Assets/LoadingSpinner/LoadingSpinner";
import { useParams } from "react-router-dom";

export default function BlogEdit() {
  const routeParams = useParams();
  const [postImg, setPostImg] = useState("");
  const [inputValues, setInputValues] = useState({});
  const [selectedFile, setSelectedFile] = useState();
  const [errorMsg, setErrorMsg] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [valuesError, setValuesError] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [news, setNews] = useState({});
  const quillRef = useRef();

  const handleEditorChange = (e) => {
    setInputValues({
      ...inputValues,
      editor: e,
    });
  };
  const handleChange = ({ target }) => {
    if (target.type === "checkbox") {
      setInputValues({
        ...inputValues,
        [target.name]: target.checked,
      });
    } else {
      setInputValues({
        ...inputValues,
        [target.name]: target.value,
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    const funcion = {};
    funcion.Function = "postNoticia";
    funcion.RequestType = "Post";
    funcion.Event = "NEWSADMIN";
    funcion.Data = { inputValues };
    funcion.usrToken = localStorage.getItem("usrToken");
    if (
      (inputValues.editor != undefined &&
        inputValues.editor != "" &&
        inputValues.titulo != undefined &&
        inputValues.titulo != "") ||
      (inputValues.image != undefined &&
        inputValues.image != "" &&
        inputValues.tituloPosteo != undefined &&
        inputValues.tituloPosteo != "")
    ) {
      setValuesError("");
      PostData("POST", funcion).then((result) => {
        //TODO #11 indicar acceso denegado
        //TODO indicar que no se pudo publicar
        //todo #14 indicar que se publico correctamente
        if (result.Respuesta != undefined && result.Respuesta == 200) {
          setIsLoading(false);
        } else {
          setShowModal(true);
        }

        // hacer algo
      }, []);
    } else {
      setValuesError(
        "Debe completar el posteo y el titulo del posteo o el titulo y el contenido de la noticia para poder publicarla"
      );
    }
  };
  const handleFileRead = async (event) => {
    const file = event.target.files[0];
    setSelectedFile(event.target.files[0]);
    const MIN_FILE_SIZE = 1; // 1KB
    const MAX_FILE_SIZE = 2000; // 5MB
    const fileSizeKiloBytes = event.target.files[0].size / 1024;
    let validFile = true;
    setErrorMsg("");
    if (fileSizeKiloBytes < MIN_FILE_SIZE) {
      setErrorMsg("File size is less than minimum limit");
      setInputValues({
        ...inputValues,
        image: "",
      });
      validFile = false;
    }
    if (fileSizeKiloBytes > MAX_FILE_SIZE) {
      setErrorMsg("File size is greater than maximum limit");
      setInputValues({
        ...inputValues,
        image: "",
      });
      validFile = false;
    }

    if (validFile) {
      const base64 = await convertBase64(file);
      setPostImg(base64);
      setInputValues({
        ...inputValues,
        image: base64,
      });
    }
  };

  const modules = {
    toolbar: {
      container: [
        [{ header: [1, 2, false] }, { font: [] }],
        ["bold", "italic", "underline", "strike", "blockquote"],
        [
          { list: "ordered" },
          { list: "bullet" },
          { indent: "-1" },
          { indent: "+1" },
        ],
        ["link", "image", "video"],
        ["clean"],
        ["blockquote", "code-block"],
        [{ script: "sub" }, { script: "super" }], // superscript/subscript
        [{ direction: "rtl" }], // text direction
        [{ size: ["small", false, "large", "huge"] }], // custom dropdown
        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        [{ align: [] }],
      ],
    },
  };

  const formats = [
    "header",
    "font",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "code-block",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
    "script",
    "color",
    "background",
    "align",
    "size",
  ];

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };
  useEffect(() => {
    setInputValues({
      ...inputValues,
      OFICINA: false,
      ADMIN: true,
      SMT: true,
      GOP: true,
      SUDO: true,
      GE: false,
      JT: false,
      EXPERTO: false,
      comentarios: false,
    });
    if (routeParams.id != undefined) {
      const funcion = {};
      funcion.Function = "getPostAdmin";
      funcion.RequestType = "Request";
      funcion.Event = "NEWSADMIN";
      funcion.usrToken = localStorage.getItem("usrToken");
      funcion.data = { id: routeParams.id };
      PostData("POST", funcion).then((result) => {
        setNews(result);

        setInputValues({
          ...inputValues,
          titulo: result.newTitle,
          editor: result.newBody,
          tituloPosteo: result.postTitle,
          copyPosteo: result.postCopy,
          image: result.postImg,
        });
        Object.keys(result.permissions).map((perm, value) => {
          setInputValues({
            ...inputValues,
            titulo: result.newTitle,
            editor: result.newBody,
            tituloPosteo: result.postTitle,
            copyPosteo: result.postCopy,
            image: result.postImg,
          });
        });

        setIsLoading(false);
      }, []);
    } else {
      setIsLoading(false);
    }
  }, []);

  return !isLoading ? (
    <div className="blogEditHolder">
      <Modal
        show={showModal}
        onHide={() => {
          setShowModal(false);
        }}
        centered
      >
        <Modal.Header>
          <Modal.Title>¡Ups! Ha ocurrido un error</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Lamentamos informarle que ha ocurrido un error al intentar publicar.
          por favor intente nuevamente. Si el error persiste, por favor contacte
          al administrador del sistema.
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => {
              setShowModal(false);
            }}
          >
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="editorArea">
        <h3>Publicacion</h3>
        <Form onSubmit={handleSubmit}>
          <Card className="cardNews">
            <h4>Posteo</h4>
            <InputGroup className="mb-3">
              <InputGroup.Text id="titulo">Posteo</InputGroup.Text>
              <Form.Control
                id="originalFileName"
                type="file"
                placeholder="Document"
                label="Document"
                name="originalFileName"
                onChange={handleFileRead}
                size="small"
                variant="standard"
                accept="image/*"
              />
              {errorMsg != "" ? (
                <div style={{ flexBasis: "100%" }}>{errorMsg}</div>
              ) : (
                <></>
              )}
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroup.Text id="titulo">Titulo Posteo</InputGroup.Text>
              <Form.Control
                type="text"
                placeholder="Titulo Posteo"
                onChange={handleChange}
                value={inputValues.tituloPosteo}
                id="tituloPosteo"
                name="tituloPosteo"
              />
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroup.Text id="titulo">Copy Posteo</InputGroup.Text>
              <Form.Control
                type="textarea"
                placeholder="Copy Posteo"
                id="copyPosteo"
                name="copyPosteo"
                value={inputValues.copyPosteo}
                onChange={handleChange}
              />
            </InputGroup>
          </Card>
          <Card className="cardNews">
            <h4>Noticia</h4>
            <InputGroup className="mb-3">
              <InputGroup.Text id="titulo">Titulo</InputGroup.Text>
              <Form.Control
                type="text"
                placeholder="Titulo"
                id="titulo"
                name="titulo"
                value={inputValues.titulo}
                onChange={handleChange}
              />
            </InputGroup>
            <InputGroup className="mb-3">
              <ReactQuill
                theme="snow"
                modules={modules}
                ref={quillRef}
                formats={formats}
                id="editor"
                value={inputValues.editor}
                name="editor"
                onChange={(e) => {
                  handleEditorChange(e);
                }}
                style={{ minHeight: "200px", marginBottom: "90px" }}
              />
            </InputGroup>
          </Card>
          <Form.Label>Visibilidad</Form.Label>
          <InputGroup className="mb-3">
            <Form.Check
              style={{ paddingRight: "10px" }}
              type="switch"
              id="OFICINA"
              name="OFICINA"
              value={inputValues.OFICINA}
              label="Oficina"
              onChange={handleChange}
            />
            <Form.Check
              style={{ paddingRight: "10px" }}
              type="switch"
              id="GE"
              name="GE"
              value={inputValues.GE}
              label="Gerentes"
              onChange={handleChange}
            />
            <Form.Check
              style={{ paddingRight: "10px" }}
              type="switch"
              id="JT"
              name="JT"
              value={inputValues.JT}
              label="Supervisores de Turno"
              onChange={handleChange}
            />
            <Form.Check
              style={{ paddingRight: "10px" }}
              type="switch"
              id="EXPERTO"
              name="EXPERTO"
              value={inputValues.EXPERTO}
              label="Experto"
              onChange={handleChange}
            />
          </InputGroup>
          <Form.Label>Comentarios [Proximamente]</Form.Label>
          <InputGroup className="mb-3">
            <Form.Check
              type="switch"
              id="comentarios"
              name="comentarios"
              label="Permitir comentarios"
              onChange={handleChange}
              disabled
            />
          </InputGroup>
          {valuesError != "" ? (
            <div className="alert alert-danger" style={{ flexBasis: "100%" }}>
              {valuesError}
            </div>
          ) : (
            <></>
          )}
          <Button className="btn-warning col-12" type="submit">
            Publicar
          </Button>
        </Form>
      </div>
      <div className="previewArea">
        <h3>Preview</h3>
        <img className="previewImg" src={inputValues.image} />
        <h4>{inputValues.tituloPosteo}</h4>
        <p>{inputValues.copyPosteo}</p>
        <div>
          <h1>{inputValues.titulo}</h1>
        </div>
        <div dangerouslySetInnerHTML={{ __html: inputValues.editor }} />
      </div>
    </div>
  ) : (
    <>
      <LoadingSpinner />
    </>
  );
}
