import React, { Component, useEffect, useState } from "react";
import "./Capacitaciones.css";
import { PostData } from "../../Services/PostData";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import CapacitacionesModule from "./CapacitacionesModule/CapacitacionesModule";
import LoadingSpinner from "../../Assets/LoadingSpinner/LoadingSpinner";

import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
/**
 * window.API.apiLogLevel = 1; // Log everything (Debug)
window.API.apiLogLevel = 2; // Log useful information, warning and errors
window.API.apiLogLevel = 3; // Log warnings and errors
window.API.apiLogLevel = 4; // Log errors only
window.API.apiLogLevel = 5; // No logging
 */
window.API.on("LMSFinish", function () {
  console.log("finalizando");
  //hacer el post publicando el resultado
  const funcion = {};
  funcion.Function = "postCapacitacion";
  funcion.RequestType = "Post";
  funcion.Event = "CAPACITACION";
  funcion.usrToken = localStorage.getItem("usrToken");
  funcion.data = {
    credit: window.API.cmi.core.credit,
    entry: window.API.cmi.core.entry,
    exit: window.API.cmi.core.exit,
    lesson_location: window.API.cmi.core.lesson_location,
    lesson_mode: window.API.cmi.core.lesson_mode,
    lesson_status: window.API.cmi.core.lesson_status,
    score: {
      raw: window.API.cmi.core.score.raw,
      min: window.API.cmi.core.score.min,
      max: window.API.cmi.core.score.max,
    },
    session_time: window.API.cmi.core.session_time,
    total_time: window.API.cmi.core.total_time,
  };

  PostData("POST", funcion).then((result) => {
    console.log("posteado");
    window.location.reload();
  }, []);

  console.log("finalizaaaaado");
});
export default function Capacitaciones() {
  const [isLoading, setIsLoading] = useState(true);
  const [capacitaciones, setCapacitaciones] = useState({});
  const [key, setKey] = useState("home");
  const [capState, setCapState] = useState([]);
  const funcion = {};
  const hanleSelect = (k) => {
    setCapState(k);
    setKey(k);
  };
  useEffect(() => {
    funcion.Function = "getCapacitaciones";
    funcion.RequestType = "Request";
    funcion.Event = "CAPACITACION";
    funcion.usrToken = localStorage.getItem("usrToken");
    PostData("POST", funcion).then((result) => {
      setCapacitaciones(result);
      setIsLoading(false);
    }, []);
  }, []);
  if (isLoading) {
    return <LoadingSpinner />;
  } else {
    return (
      <div className="capHome">
        <>
          <Tab.Container
            id="left-tabs-example"
            defaultActiveKey="first"
            /*activeKey={key}*/
            onSelect={hanleSelect}
            mountOnEnter
            unmountOnExit
          >
            <Row className="hw100">
              <Col key={"navitems"} id="uniqueid1" sm={3}>
                <Nav variant="pills" className="flex-column">
                  <Nav.Item key={1} className="button-nav button-elem">
                    <Nav.Link eventKey="first">
                      Estado de Capacitaciones
                    </Nav.Link>
                  </Nav.Item>
                  {Object.keys(capacitaciones).map((key) => {
                    return (
                      <Nav.Item
                        key={`cap` + capacitaciones[key].ID}
                        className="button-nav button-elem"
                      >
                        <Nav.Link eventKey={capacitaciones[key].ID}>
                          {capacitaciones[key].nombre}
                        </Nav.Link>
                      </Nav.Item>
                    );
                  })}
                  <Nav.Item key={99} className="btn-danger button-nav">
                    <Nav.Link
                      href={
                        "https://dehezasa-my.sharepoint.com/:u:/g/personal/federico_schwemler_deheza_com_ar/EYVy8n-qEKJEt_9nBfFYUkYBewUNaWXVbb2xYgDOT8or-A?download=1"
                      }
                      style={{ color: "#FFF" }}
                    >
                      Descargar Código de Conducta
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
              <Col key={"contentItems"} id="uniqueid2" sm={9}>
                <Tab.Content className="hw100">
                  <Tab.Pane key={"pane1"} eventKey="first">
                    <CapacitacionesModule cap={capacitaciones} start={true} />
                  </Tab.Pane>
                  {Object.keys(capacitaciones).map((key) => {
                    return (
                      <Tab.Pane
                        className="tabContent"
                        key={`pane` + capacitaciones[key].ID}
                        eventKey={capacitaciones[key].ID}
                      >
                        <CapacitacionesModule
                          cap={capacitaciones[key]}
                          start={false}
                        />
                      </Tab.Pane>
                    );
                  })}
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </>
      </div>
    );
  }
}
