import React, { Component } from "react";
import { useState, useEffect, useContext } from "react";
import { Redirect } from "react-router-dom";
import { PostData } from "../../Services/PostData";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import "./Login.css";
import "bootstrap/dist/css/bootstrap.min.css";
import BackgroundImageOnLoad from "background-image-on-load";
import bgImg from "../../Assets/Images/Login/img_blur.jpg";
import bg1 from "../../Assets/Images/Login/bg_1.jpg";
import bg2 from "../../Assets/Images/Login/bg_2.jpg";
import bg3 from "../../Assets/Images/Login/bg_3.jpg";
import bg4 from "../../Assets/Images/Login/bg_4.jpg";
import bg5 from "../../Assets/Images/Login/bg_5.jpg";
import bg6 from "../../Assets/Images/Login/bg_6.jpg";
import { FloatingLabel } from "react-bootstrap";
import LoadingSpinner from "../../Assets/LoadingSpinner/LoadingSpinner";
import { SigninContext } from "../../Context/Context";

const images = [bg1, bg2, bg3, bg4, bg5, bg6];

export default function Login() {
  const { pwChange, setPWChange, setProfileFill } = useContext(SigninContext);
  const [Username, setUsername] = useState("");
  const [Password, setPassword] = useState("");
  const [loginError, setLoginError] = useState(false);
  const [isLogginin, setIsLogginin] = useState(false);
  const [Error, setError] = useState("");
  const [redirectToReferrer, setRedirectToReferrer] = useState(false);
  const [bgIsLoaded, setBgIsLoaded] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(
    Math.floor(Math.random() * images.length)
  );
  const [show, setShow] = useState(false);
  const [recoverIsLoading, setRecoverIsLoading] = useState(false);
  const [recoverSucceded, setRecoverSucceded] = useState(false);
  const [recoverError, setRecoverError] = useState(false);
  const [Email, setEmail] = useState("");
  document.title = "Deheza - Iniciar Sesión";
  const reset = (e) => {
    e.preventDefault();
    const funcion = {};
    funcion.Function = "resetPassword";
    funcion.Data = { Username: Username, Email: Email };
    setRecoverIsLoading(true);
    setRecoverSucceded(false);
    setRecoverError(false);
    PostData("POST", funcion).then((result) => {
      let responseJson = result;
      if (responseJson.Respuesta == 200) {
        setRecoverIsLoading(false);
        setRecoverSucceded(true);
        setRecoverError(false);
      } else {
        setRecoverIsLoading(false);
        setRecoverSucceded(false);
        setRecoverError(true);
      }
    });
  };
  const handleClose = () => {
    setShow(false);
    setRecoverIsLoading(false);
    setRecoverSucceded(false);
    setRecoverError(false);
  };
  const login = (e) => {
    e.preventDefault();
    setIsLogginin(true);
    const funcion = {};
    if (Username && Password) {
      funcion.Function = "Login";
      funcion.Data = { Username: Username, Password: Password };
      PostData("POST", funcion).then((result) => {
        let responseJson = result;
        if (responseJson.usrToken != undefined && responseJson.usrToken != "") {
          localStorage.setItem("usrToken", responseJson.usrToken);
          localStorage.setItem("Alert", responseJson.Alert);
          localStorage.setItem("ValidTime", responseJson.ValidTime);
          localStorage.setItem("pswChange", responseJson.pswChange);
          localStorage.setItem("profileFill", responseJson.profileFill);
          setPWChange(responseJson.pswChange == 1 ? true : false);
          setProfileFill(responseJson.profileFill == 1 ? true : false);
          setRedirectToReferrer(true);
        } else {
          setIsLogginin(false);
          setLoginError(true);

          setError(responseJson.Descripcion);
        }
      });
    }
  };
  const onChange = (e) => {
    if (e.target.name == "Username") {
      setUsername(e.target.value);
    } else if (e.target.name == "Password") {
      setPassword(e.target.value);
    } else if (e.target.name == "Email") {
      setEmail(e.target.value);
    }
  };
  if (redirectToReferrer) {
    return <Redirect to={"/"} />;
  } else {
    return (
      <div>
        <div
          className="login-background"
          style={{
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundImage: `url(${
              !bgIsLoaded ? bgImg : images[currentImageIndex]
            })`,
          }}
        >
          <BackgroundImageOnLoad
            src={images[currentImageIndex]}
            onLoadBg={() => {
              setBgIsLoaded(true);
            }}
            onError={(err) => console.log("error", err)}
          />
        </div>
        <div
          className="modal show"
          style={{ display: "block", position: "initial" }}
        >
          <Modal.Dialog className="modal-login">
            <Form onSubmit={login}>
              <Modal.Header>
                <div className="avatar">
                  <img
                    src="/images/android-icon-72x72.png"
                    alt="Logo Deheza"
                    width={65}
                    height={65}
                  />
                </div>
                <Modal.Title>¡Bienvenido!</Modal.Title>
              </Modal.Header>
              {isLogginin ? (
                <LoadingSpinner />
              ) : (
                <>
                  <Modal.Body>
                    {loginError ? <Form.Label>{Error}</Form.Label> : <></>}
                    <Form.Group>
                      <FloatingLabel
                        controlId="floatingInputGriduser"
                        label="Usuario"
                        required
                      >
                        <Form.Control
                          type="text"
                          className="form-input"
                          name="Username"
                          placeholder="Usuario"
                          required="required"
                          aria-label="usuario"
                          autoComplete="username"
                          onChange={onChange}
                          style={{ marginbottom: 15 }}
                        />
                      </FloatingLabel>
                    </Form.Group>
                    <Form.Group controlId="formBasicPassword">
                      <FloatingLabel
                        controlId="floatingInputGridpass"
                        label="Contraseña"
                        required
                      >
                        <Form.Control
                          aria-label="contrseña"
                          type="password"
                          className="form-input"
                          name="Password"
                          placeholder="Contraseña"
                          autoComplete="current-password"
                          onChange={onChange}
                        />
                      </FloatingLabel>
                    </Form.Group>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      variant="warning"
                      type="submit"
                      style={{ color: "#000" }}
                    >
                      Iniciar Sesión
                    </Button>
                    <div style={{ textAlign: "left" }}>
                      <a
                        href="#"
                        onClick={() => {
                          setShow(true);
                        }}
                      >
                        ¿Olvidaste la contraseña?
                      </a>
                    </div>
                  </Modal.Footer>
                </>
              )}
            </Form>
          </Modal.Dialog>
        </div>
        <>
          <Modal
            show={show}
            onHide={() => {
              handleClose();
            }}
            centered
          >
            <Modal.Header>
              <Modal.Title>Reestablecer Contraseña</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {recoverIsLoading ? (
                <LoadingSpinner />
              ) : recoverSucceded ? (
                <>
                  Hemos solicitado el reestablecimiento de contraseña de manera
                  exitosa.
                  <br />
                  Si la el mail registrado para el usuario ingresado existe
                  recibiras un enlace para reestablecer tu clave.
                  <br />
                  <br />
                  En caso de que no recibas por favor contacta a
                  support@deheza.ar
                </>
              ) : recoverError ? (
                <>
                  Ha ocurrido un error en el servidor al momento de solicitar el
                  blanqueo de la clave. <br />
                  <br />
                  Por favor contacta a support@deheza.ar para mayor asistencia
                </>
              ) : (
                <>
                  <Form onSubmit={reset}>
                    <Form.Group>
                      <FloatingLabel
                        controlId="floatingInputGriduser"
                        label="Usuario"
                        required
                      >
                        <Form.Control
                          type="text"
                          className="form-input"
                          name="Username"
                          placeholder="Usuario"
                          required="required"
                          aria-label="usuario"
                          autoComplete="username"
                          onChange={onChange}
                          style={{ marginbottom: 15 }}
                        />
                      </FloatingLabel>
                    </Form.Group>
                    <Form.Group controlId="formBasicPassword">
                      <FloatingLabel
                        controlId="floatingInputGridpass"
                        label="Email"
                        required
                      >
                        <Form.Control
                          aria-label="email"
                          type="text"
                          className="form-input"
                          name="Email"
                          placeholder="Email"
                          autoComplete="email"
                          onChange={onChange}
                        />
                      </FloatingLabel>
                    </Form.Group>

                    <Button
                      variant="warning"
                      type="submit"
                      style={{ color: "#000" }}
                    >
                      Reestablecer Contraseña
                    </Button>
                  </Form>
                </>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="primary"
                onClick={() => {
                  handleClose();
                }}
              >
                Cancelar
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      </div>
    );
  }
}
