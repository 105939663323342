import { useParams } from "react-router-dom";
import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { PostData } from "../../Services/PostData";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import "./ChangePassword.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { FloatingLabel } from "react-bootstrap";
import LoadingSpinner from "../../Assets/LoadingSpinner/LoadingSpinner";
import { useState } from "react";
import { usePasswordValidation } from "../../Services/PasswordValidate";
import { Windows } from "react-bootstrap-icons";

export default function PasswordChange(props) {
  const routeParams = useParams();
  const [loading, setLoading] = useState(false);
  const [changeStatus, setChangeStatus] = useState("");
  const [password, setPassword] = useState({
    firstPassword: "",
    secondPassword: "",
  });

  const [validLength, hasNumber, upperCase, lowerCase, match, specialChar] =
    usePasswordValidation({
      firstPassword: password.firstPassword,
      secondPassword: password.secondPassword,
      requiredLength: 8,
    });

  const onChange = ({ target }) => {
    setPassword({
      ...password,
      [target.name]: target.value,
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const funcion = {};
    funcion.Function = "changePassword";
    funcion.RequestType = "Post";
    funcion.usrToken = localStorage.getItem("usrToken");
    funcion.Data = {
      oldpassword: password.oldPassword,
      password: password.firstPassword,
      link: routeParams.id,
    };

    PostData("POST", funcion).then((result) => {
      if (typeof result.Respuesta != "undefined") {
        if (result.Respuesta == 200) {
          setChangeStatus("success");
          localStorage.clear();
          setTimeout(() => {
            window.location.reload();
          }, 5000);
        } else {
          setChangeStatus("authError");
        }
      } else {
        setChangeStatus("generalError");
      }
    }, []);
  };

  return (
    <div className="changepassItem">
      <Form onSubmit={handleSubmit} className="passwordForm">
        {loading ? (
          <LoadingSpinner />
        ) : changeStatus == "" ? (
          <div>
            <Form.Group>
              <h2>Cambiar Contraseña</h2>
              {props.isForced ? (
                <div>
                  Por cuestiones de seguridad te pedimos que cambies tu
                  contraseña para evitar ingresos indeseados a tu cuenta. Por
                  favor, ingresa tu contraseña actual y luego tu nueva
                  contraseña.
                  <br />
                  <strong>
                    No podras acceder a ninguna seccion del sitio hasta que no
                    realices el cambio.
                  </strong>
                </div>
              ) : (
                <h4>
                  Escribe tu contraseña actual y tu nueva contraseña. Una vez
                  que validemos todos los requisitos de seguridad de la misma
                  podras guardarla.
                </h4>
              )}
              <FloatingLabel
                controlId="floatingInputGriduserpassold"
                label="Contraseña Actual"
                required
              >
                <Form.Control
                  type="password"
                  className="form-input"
                  name="oldPassword"
                  placeholder="Contraseña Actual"
                  required="required"
                  aria-label="olsPassword"
                  onChange={onChange}
                  style={{ marginbottom: 15 }}
                />
              </FloatingLabel>
            </Form.Group>
            <Form.Group>
              <FloatingLabel
                controlId="floatingInputGriduserpassnew"
                label="Nueva contraseña"
                required
              >
                <Form.Control
                  type="password"
                  className="form-input"
                  name="firstPassword"
                  placeholder="Nueva contraseña"
                  required="required"
                  aria-label="nueva contraseña"
                  onChange={onChange}
                  style={{ marginbottom: 15 }}
                />
              </FloatingLabel>
            </Form.Group>
            <Form.Group controlId="formBasicPassword">
              <FloatingLabel
                controlId="floatingInputGridpass"
                label="Repetir Contraseña"
                required
              >
                <Form.Control
                  aria-label="repetircontrseña"
                  type="password"
                  className="form-input"
                  name="secondPassword"
                  placeholder="Contraseña"
                  onChange={onChange}
                />
              </FloatingLabel>
            </Form.Group>
            {password.firstPassword != "" &&
            !(
              validLength &&
              hasNumber &&
              upperCase &&
              lowerCase &&
              match &&
              specialChar
            ) ? (
              <>
                La contraseña debe tener:
                <br />
                {validLength ? (
                  <></>
                ) : (
                  <span>
                    8 Caracteres
                    <br />
                  </span>
                )}
                {hasNumber ? (
                  <></>
                ) : (
                  <span>
                    1 numero
                    <br />
                  </span>
                )}
                {upperCase ? (
                  <></>
                ) : (
                  <span>
                    1 mayuscula
                    <br />
                  </span>
                )}
                {lowerCase ? (
                  <></>
                ) : (
                  <span>
                    1 minuscula
                    <br />
                  </span>
                )}
                {specialChar ? (
                  <></>
                ) : (
                  <span>
                    1 caracter especial
                    <br />
                  </span>
                )}
                {match ? (
                  <></>
                ) : (
                  <span>
                    Deben coincidir
                    <br />
                  </span>
                )}
              </>
            ) : (
              <></>
            )}

            {validLength &&
            hasNumber &&
            upperCase &&
            lowerCase &&
            match &&
            specialChar ? (
              <Modal.Footer>
                <Button
                  variant="warning"
                  type="submit"
                  style={{ color: "#000" }}
                >
                  Cambiar Contraseña
                </Button>
              </Modal.Footer>
            ) : (
              <></>
            )}
          </div>
        ) : changeStatus == "success" ? (
          <>
            {" "}
            ¡Cambio exitoso!
            <br />
            Ya podes iniciar sesion nuevamente con tu nueva contraseña.
          </>
        ) : changeStatus == "authError" ? (
          <>
            La contraseña actual ingresada no es correcta. Por favor, intenta
            nuevamente.
          </>
        ) : (
          <>
            Ha ocurrido un error general.
            <br />
            Por favor volve a intenter mas tarde
          </>
        )}
      </Form>
    </div>
  );
}
