import React, { Component, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import LoadingSpinner from "../../../Assets/LoadingSpinner/LoadingSpinner";
import { PostData } from "../../../Services/PostData";
import "./HomeNews.css";

export default function HomeNews() {
  const [isLoading, setIsLoading] = useState(true);
  const [news, setNews] = useState({});
  const [isPost, setIsPost] = useState(false);
  const [isNews, setIsNews] = useState(false);
  const [newsTitle, setNewsTitle] = useState("");
  const [newsBody, setNewsBody] = useState("");
  const [postTitle, setPostTitle] = useState("");
  const [postCopy, setPostCopy] = useState("");
  const [postImg, setPostImg] = useState("");
  const [uid, setUid] = useState("");
  const funcion = {};
  useEffect(() => {
    funcion.Function = "getLastNews";
    funcion.RequestType = "Request";
    funcion.Event = "NEWS";
    funcion.usrToken = localStorage.getItem("usrToken");
    funcion.data = { limit: 1 };
    PostData("POST", funcion).then((result) => {
      console.log(result);
      setUid(result.UID);
      if (result.postImg != null && result.postImg != "") {
        setIsPost(true);
        setPostTitle(result.postTitle);
        setPostCopy(result.postCopy);
        setPostImg(result.postImg);
      } else if (result.newBody != null && result.newBody != "") {
        setIsNews(true);
        setNewsBody(result.newBody);
        setNewsTitle(result.newTitle);
      }
      //setNews(result);
      setIsLoading(false);
    }, []).catch((error) => {
      setNewsTitle("Error de conexión, por favor intente nuevamente.");
      setIsLoading(false);
    });
  }, []);

  return (
    <div>
      {isLoading ? (
        <LoadingSpinner />
      ) : isPost ? (
        <div className="postContainer">
          <Link to={"/dashboard/blog/post/" + uid}>
            <img src={postImg} className="homePostImg" />
            <h2>{postTitle}</h2>
            <p>{postCopy}</p>
          </Link>
        </div>
      ) : isNews ? (
        <>
          <div className="postContainer">
            <Link to={"/dashboard/blog/post/" + uid}>
              <h1>{newsTitle}</h1>
              <div dangerouslySetInnerHTML={{ __html: newsBody }}></div>
            </Link>
          </div>
        </>
      ) : (
        <>¡Ups! Parece que tenemos un problema.</>
      )}
    </div>
  );
}
