import React, { useEffect, useState, Component } from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import "bootstrap/dist/css/bootstrap.min.css";
import { Power } from "react-bootstrap-icons";
import logo from "../../Assets/Images/LogoDehezaribbon2.png";
import { Redirect } from "react-router-dom";
import { Nav, Navbar, NavDropdown } from "react-bootstrap";
import { PostData } from "../../Services/PostData";
import Accordion from "react-bootstrap/Accordion";
import Menu from "react-burger-menu/lib/menus/scaleDown";
import AccordionItem from "react-bootstrap/esm/AccordionItem";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import Card from "react-bootstrap/Card";
import "./Nav.css";

function CustomToggle({ children, eventKey }) {
  const decoratedOnClick = useAccordionButton(eventKey, () =>
    console.log("totally custom!")
  );

  return <div> {children}</div>;
}

function NavigationBar(props) {
  const [homeB, setHomeB] = useState(props.data);
  const [loading, setLoading] = useState(true);
  var styles = {
    bmBurgerButton: {
      position: "fixed",
      width: "25px",
      height: "20px",
      left: "4px",
      top: "4px",
    },
    bmBurgerBars: {
      background: "#373a47",
    },
    bmBurgerBarsHover: {
      background: "#a90000",
    },
    bmCrossButton: {
      height: "24px",
      width: "24px",
    },
    bmCross: {
      background: "#bdc3c7",
    },
    bmMenuWrap: {
      position: "fixed",
      height: "100%",
    },
    bmMenu: {
      background: "#BBB",
      padding: "1em 0.5em 0",
      fontSize: "1em",
    },
    bmMorphShape: {
      fill: "#373a47",
    },
    bmItemList: {
      color: "#b8b7ad",
      padding: "0.8em",
    },
    bmItem: {},
    bmOverlay: {
      background: "rgba(0, 0, 0, 0.3)",
    },
  };

  useEffect(() => {
    if (localStorage.getItem("homeButtons") != null) {
      setHomeB(JSON.parse(localStorage.getItem("homeButtons")));
      setLoading(false);
    }
  }, []);

  if (!loading) {
    return (
      <Menu
        pageWrapId={"page-wrap"}
        outerContainerId={"outer-container"}
        styles={styles}
      >
        <Nav className="flex-column" key={1}>
          <Accordion>
            <Card key={1}>
              <Card.Header
                as={Link}
                to={`/dashboard/`}
                className="accordion-button"
                id="inicio"
              >
                Inicio
              </Card.Header>
            </Card>

            {Object.keys(homeB).map((item, key) => {
              if (homeB[item].sID != 0) {
                return (
                  <Accordion.Item
                    title={homeB[item].sNOMBRE}
                    eventKey={homeB[item].sID}
                    key={homeB[item].sID}
                    id="collasible-nav-dropdown"
                  >
                    <Accordion.Header>{homeB[item].sNOMBRE}</Accordion.Header>
                    <Accordion.Body>
                      {Object.keys(homeB[item].botones).map(
                        (subitem, subkey) => {
                          if (subitem != "GROUPED") {
                            return (
                              <NavDropdown.Item
                                as={Link}
                                to={
                                  homeB[item].botones[subitem].RInternal == ""
                                    ? "/dashboard/" +
                                      homeB[item].botones[subitem].bINTNAME
                                    : "/dashboard/" +
                                      homeB[item].botones[subitem].RInternal
                                }
                                key={homeB[item].botones[subitem].bID}
                                report={homeB[item].botones[subitem].RInternal}
                              >
                                {homeB[item].botones[subitem].bNOMBRE +
                                  " " +
                                  homeB[item].botones[subitem].bDESCRIPCION}
                              </NavDropdown.Item>
                            );
                          } else {
                            return Object.keys(
                              homeB[item].botones[subitem]
                            ).map((subitemgr, subkeygr) => {
                              return (
                                <Accordion
                                  key={"acordionkey" + subitemgr}
                                  className="accsubmenu"
                                >
                                  <Accordion.Item
                                    title={homeB[item].sNOMBRE}
                                    eventKey={homeB[item].sID}
                                    key={homeB[item].sID}
                                    id="collasible-nav-dropdown"
                                  >
                                    <Accordion.Header>
                                      {
                                        homeB[item].botones[subitem][subitemgr]
                                          .gNOMBRE
                                      }
                                    </Accordion.Header>
                                    <Accordion.Body>
                                      {Object.keys(
                                        homeB[item].botones[subitem][subitemgr]
                                      ).map((grupsubitem, groupsubkey) => {
                                        if (
                                          typeof homeB[item].botones[subitem][
                                            subitemgr
                                          ][groupsubkey] != "undefined"
                                        ) {
                                          return (
                                            <NavDropdown.Item
                                              as={Link}
                                              to={
                                                homeB[item].botones[subitem][
                                                  subitemgr
                                                ][grupsubitem].RInternal == ""
                                                  ? "/dashboard/" +
                                                    homeB[item].botones[
                                                      subitem
                                                    ][subitemgr][grupsubitem]
                                                      .bINTNAME
                                                  : "/dashboard/" +
                                                    homeB[item].botones[
                                                      subitem
                                                    ][subitemgr][grupsubitem]
                                                      .RInternal
                                              }
                                              key={
                                                "grupokey" +
                                                homeB[item].botones[subitem][
                                                  subitemgr
                                                ][grupsubitem].bID
                                              }
                                              report={
                                                homeB[item].botones[subitem][
                                                  subitemgr
                                                ][grupsubitem].RInternal
                                              }
                                            >
                                              {homeB[item].botones[subitem][
                                                subitemgr
                                              ][grupsubitem].bNOMBRE +
                                                " " +
                                                homeB[item].botones[subitem][
                                                  subitemgr
                                                ][grupsubitem].bDESCRIPCION}
                                            </NavDropdown.Item>
                                          );
                                        }
                                      })}
                                    </Accordion.Body>
                                  </Accordion.Item>
                                </Accordion>
                              );
                            });
                          }
                        }
                      )}
                    </Accordion.Body>
                  </Accordion.Item>
                );
              } else {
                {
                  return Object.keys(homeB[item].botones).map(
                    (subitem, subkey) => {
                      if (subitem != "GROUPED") {
                        return (
                          <Card key={2}>
                            <Card.Header
                              style={{ padding: "16px" }}
                              as={Link}
                              to={
                                homeB[item].botones[subitem].RInternal == ""
                                  ? "/dashboard/" +
                                    homeB[item].botones[subitem].bINTNAME
                                  : "/dashboard/" +
                                    homeB[item].botones[subitem].RInternal
                              }
                              key={homeB[item].botones[subitem].bID}
                              report={homeB[item].botones[subitem].RInternal}
                            >
                              {homeB[item].botones[subitem].bNOMBRE +
                                " " +
                                homeB[item].botones[subitem].bDESCRIPCION}
                            </Card.Header>
                          </Card>
                        );
                      }
                    }
                  );
                }
              }
            })}
          </Accordion>
        </Nav>

        <Nav className="ml-auto homeLinkImage" id="logoutbuttoncontainer">
          <Nav.Link eventKey={2} onClick={props.logout}>
            <Power className="Logout" />
          </Nav.Link>
        </Nav>
      </Menu>
    );
  } else {
    return (
      <Menu
        pageWrapId={"page-wrap"}
        outerContainerId={"outer-container"}
        styles={styles}
      >
        <Nav className="flex-column" key={1}>
          <Link to="/" className="homeLinkImage">
            <img
              src={logo}
              alt=""
              width="100"
              height="100"
              className="d-inline-block align-top"
            />
          </Link>

          <Nav.Link
            eventKey={2}
            onClick={props.logout}
            className="homeLinkImage"
          >
            <Power className="Logout" />
          </Nav.Link>
        </Nav>
      </Menu>
    );
  }
}

export default NavigationBar;
